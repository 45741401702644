.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.activeLink {
  display: flex;
  /* background-color: gray; */
  padding-inline: 10px;
  padding-block: 4px;
  align-items: center;
  gap: 7px;
  color: #000;
  font-size: medium;
  font-weight: 600;
}

.activeLink:hover {
  background-color: #e0a604;
}

.right_btn_2 {
  position: absolute;
  left: 105%;
}
.update_icon {
  display: none;
}
@media screen and (max-width: 900px) {
  .cancel_btn {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .right_btn_2 {
    position: relative;
    left: 0%;
  }
  .update_btn {
    display: none;
  }
  .update_icon {
    display: block;
  }
}

.home_bg {
  background-image: var(--bgimg);

  /* background-image:url('./images/HomeBg.jpg'); */
  /* background-image:url('./images/insugo_img/family.png'); */
  /* background-image:url('./images/insugo_img/house.png'); */
  /* background-image:url('./images/insugo_img/plant.png'); */
  /* background-image:url('./images/insugo_img/umbrella_2.png'); */

  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgb(0, 0, 0, 0.6) !important;
  /* filter: brightness(.5); */
}
.home_bg_2 {
  /* filter: brightness(3); */
}

/* Footer Dropdown  */

.dropdown-ft {
  position: relative;
  display: inline-block;
  background-color: #8b7b7b;
  width: 160px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  border: 1px solid #fff;
  margin-bottom: 0.5rem;
  font-size: 17px;
  border-radius: 3px;
}
.down_arr {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}
.down_arr_2 {
  transition: transform 0.5s ease;
}

.dropdown-ft-content {
  /* display: none; */
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 160px;
}

.dropdown-ft-content div {
  color: black;
  font-size: 16px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-ft-content a:hover {
  background-color: #f1f1f1;
}

.dropdown-ft:hover .dropdown-ft-content {
  display: block;
}

/* .css-1n6sfyn-MenuList{
  color: #000 !important;
}
#react-select-2-listbox {
  color: #000 !important;
} */

.background-video {
  border: 1px solid #fff;
  margin: 1rem 0;
  border-radius: 2px;
  width: 100%;
  height: 80vh;
  object-fit: fill;
  z-index: 0;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

.controls button {
  padding: 10px 12px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* video */
.videos_main_div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  gap: 0.5rem;
}
.video-main {
  position: relative;
  width: 100%;
  height: 100%;
}
.play-icon {
  height: 50px;
  width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
@media screen and (max-width: 1025px) {
  .videos_main_div {
    padding: 10px;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .videos_main_div > div {
    max-width: 350px;
  }
}

@media screen and (max-width: 500px) {
  .videos_main_div {
    padding: 10px;
    gap: 1rem;
    flex-wrap: wrap;
  }
}


/* Marqee css */

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #fff;
}

.marquee p {
  width: 100%;
  display: inline-block;
  animation: marquee linear infinite;
  padding: 3px 0;
  color: blue;
  font-weight: bold;
  animation-play-state: running; /* Default state */
}

/* Pause animation on hover */
.marquee p:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
